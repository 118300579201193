import { createContext, useEffect, useState, useRef } from "react";
import FindLilith from "../assets/videos/FindLilith.mp4";
import { deviceWidthInPixel } from "../const/device";
import FindLilithMobile from "../assets/videos/FindLilith-mobile.mp4";

const SceneStageContext = createContext({
  stage: 0,
  setStage: () => {},
  setNextStage: () => {},
  setPrevStage: () => {},
  isMuted: false,
  toggleIsMuted: () => {},
  setIsMuted: () => {},
});

const SceneStageProvider = ({ children }) => {
  const [stage, setStage] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [videoSrc, setVideoSrc] = useState();
  const setNextStage = () => setStage((prevStage) => prevStage + 1);
  const setPrevStage = () => setStage((prevStage) => prevStage - 1);
  const toggleIsMuted = () => setIsMuted((prev) => !prev);
  const videoDownloadRef = useRef();

  useEffect(() => {
    // Preload video
    if (!videoDownloadRef.current) {
      videoDownloadRef.current = true;
      const req = new XMLHttpRequest();
      const isMobile = window.innerWidth < deviceWidthInPixel.tablet
      req.open("GET", isMobile ? FindLilithMobile : FindLilith, true);
      req.responseType = "blob";

      req.onload = function () {
        // Onload is triggered even on 404
        // so we need to check the status code
        console.log("video load");
        if (this.status === 200) {
          const videoBlob = this.response;
          const vid = URL.createObjectURL(videoBlob); // IE10+
          // Video is now downloaded
          // and we can set it as source on the video element
          setVideoSrc(vid);
        }
      };
      req.onerror = function () {
        // Error
        console.log("fail to preload video");
      };

      req.send();
    }
  }, []);

  return (
    <SceneStageContext.Provider
      value={{
        stage,
        setStage,
        setNextStage,
        setPrevStage,
        isMuted,
        toggleIsMuted,
        setIsMuted,
        videoSrc,
      }}
    >
      {children}
    </SceneStageContext.Provider>
  );
};

export { SceneStageProvider, SceneStageContext };
