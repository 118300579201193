import { HalfFloatType, LinearEncoding, LinearFilter, Mesh, PlaneBufferGeometry, RGBAFormat, WebGLRenderer, WebGLRenderTarget } from "three";
import { EventBus } from "../EventDispatcher";
import { Root } from "../Root";
import { provide } from "../global/Uniforms";
import { Bloom } from "./fx/Bloom";
import { Composer } from "./Composer";
import { Halo } from "./fx/Halo";
import { Vignette } from "./fx/Vignette";
import { Dither } from "./fx/Dither";
import { DoF } from "./fx/DoF";
import { ACESToneMapping } from "./fx/ACESToneMapping";

export class RenderingPipeline {

  static rtParameters = {
    magFilter: LinearFilter,
    minFilter: LinearFilter,
    generateMipmaps: false,
    type: HalfFloatType,
    format: RGBAFormat,
    encoding: LinearEncoding,
  };

  constructor() {
    this.renderer = new WebGLRenderer({
      antialias: false,
      powerPreference: "high-performance",
      stencil: false,
    });

    this.capabilities = this.renderer.capabilities;

    this.quad = new Mesh(
      new PlaneBufferGeometry(1, 1, 1, 1),
      null
    );
    this.quad.frustumCulled = false;

    this.canvasScale = 1;
    this.superSampling = 1;

    this.sceneRT = new WebGLRenderTarget(
      Root.screen.x * this.superSampling,
      Root.screen.y * this.superSampling,
      RenderingPipeline.rtParameters
    );

    this.fx = [
      new DoF(this.capabilities),
      new Bloom(),
      new Halo(),
      new ACESToneMapping(),
      new Vignette(),
      new Dither(),
    ];

    this.composer = new Composer(this.fx);

    EventBus.on("resize", this.onResize);
  }

  setContainer = container => {
    container.appendChild(this.renderer.domElement);
    
    this.onResize(Root.screen);
  };

  render = () => {
    const { scene, camera } = Root;
    
    this.renderer.setRenderTarget(this.sceneRT);
    this.renderer.render(scene, camera);
    provide("post.screen", this.sceneRT.texture);
    provide("post.screen.raw", this.sceneRT.texture);

    this.fx.forEach(f => f.render && f.render(this.renderer, this.quad, camera));

    this.composer.render(this.renderer, this.quad, camera);

  };

  onResize = ({ x, y }) => {
    this.renderer.setSize(x * this.canvasScale, y * this.canvasScale);
    this.renderer.setPixelRatio(1);

    this.sceneRT.setSize(x * this.superSampling, y * this.superSampling);
  };
}