import { MathUtils } from "three";

MathUtils.seededRandom(3);

export function rr(from, to) {
  return from + MathUtils.seededRandom() * (to - from);
}

export function itm(arr) {
  return arr[Math.floor(arr.length * MathUtils.seededRandom())];
}