export const initialProgress = 0;

export const assets = {
  env: {
    type: "env",
    path: "textures/env.jpg",
  },
  envMap: "textures/env.jpg",
  diffuse: "/textures/comet/diffuse.jpg",
  metalness: "/textures/comet/metalness.jpg",
  normal: "/textures/comet/normals.jpg",
  roughness: "/textures/comet/roughness.jpg",
  comet: "models/comet.obj",

  rockDiffuse: "/textures/rock/diffuse.jpg",
  rockMetalness: "/textures/rock/metalness.jpg",
  rockNormal: "/textures/rock/normals.jpg",
  rockRoughness: "/textures/rock/roughness.jpg",
  rock: "models/rock.obj",
};

export const settings = {
  devMode: false,

  camera: {
    positions: [
      [-0, -0, 160],
      [-50, -0, 50],
      [-30, 0, 0],
      [-0, -0, -20],
    ],
    targets: [
      [0, 0, 10],
      [0, 0, 5],
      [0, 0, 0],
      [0, 0, -5],
    ],
    point1Index: 2,
  },

  comet: {
    rotationSpeed: 3.0,
  },
  satellite: {
    rotationSpeed: 3.0,
  },

  light: {
    position: [-1, 2, 4],
    intensity: 5,
    color: 0xffffff,
  },

  light2: {
    position: [-1, 0, 0],
    intensity: 0.5,
    color: 0xffffff,
  },

  starsLightIntensity: 0.2,

  DoF: {
    blurDistance: [0, 0, 1, 2, 3, 0, 0, 200, 1000],
    maxBlur: [0.2, 0.2, 0.4, 0.6, 1.0, 1.0, 2.0, 1.0, 0.0],
    focusShift: [0, 0, 0, -1, -2.5, -5, -20, -10, 0],
  },

  exposure: 0.5,
  haloPower: 0.5,
  bloomPower: 1,
  vignettePower: 0.25,
};
