import { useState, Fragment } from "react";
import { Typography, Notification, Spin } from "@arco-design/web-react";
import styled from "styled-components";
import { useRequest } from "ahooks";
import { sendEmail } from "../api/email";
import emailInputBackground from "../assets/pngs/email-input-background.png";
import { ReactComponent as InputSubmitIcon } from "../assets/svgs/input-submit.svg";
import { ReactComponent as InputSubmitHighlightIcon } from "../assets/svgs/input-submit-highlight.svg";
import EmailModal from "./EmailModal";
import { device } from "../const/device";
import { emailRegex } from "../utils/regex";

const Container = styled.div`
  position: fixed;
  width: 410px;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  @media ${device.mobileLMaxWidth} {
    width: 300px !important;
  }
`;

const Input = styled.input`
  width: 100%;
  background: none;
  height: 50px;
  color: white;
  font-family: N27;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.03em;
  text-align: left;
  padding: 12px 16px;
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: "N27";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #333333;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  opacity: 0.9;
  backdrop-filter: blur(7.40521px);
  background-image: url(${emailInputBackground});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 8px 0 0;

  @media ${device.mobileLMaxWidth} {
    height: 42px;
  }
`;

const SpinContainer = styled.div`
  margin-right: 16px;
  .arco-spin {
    .arco-spin-icon {
      color: lightgrey;
    }
  }
`;

const InputBox = () => {
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const { loading, run } = useRequest(sendEmail, {
    manual: true,
    onError: (err) => {
      Notification.error({
        title: err.message,
        content: err.message,
      });
    },
    onSuccess: (result, params) => {
      if (result.code === 0) {
        setVisible(true);
      }
    },
  });

  const handleOnChange = (e) => {
    const lintedEmail = String(e.target.value).trim().toLowerCase();
    setIsEmailValid(emailRegex.test(lintedEmail));
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    run(email);
  };

  return (
    <>
      <Container>
        <InputContainer>
          <Input placeholder="EMAIL HERE ..." onChange={handleOnChange}></Input>
          {email && isEmailValid && !loading ? (
            <InputSubmitHighlightIcon
              style={{ marginRight: 8, cursor: "pointer" }}
              onClick={handleSubmit}
            />
          ) : loading ? (
            <Fragment>
              <SpinContainer>
                <Spin />
              </SpinContainer>
              <InputSubmitIcon style={{ marginRight: 8 }} />
            </Fragment>
          ) : (
            <InputSubmitIcon style={{ marginRight: 8 }} />
          )}
        </InputContainer>
        {email && !isEmailValid ? (
          <Typography.Text type="error">Email is not valid</Typography.Text>
        ) : null}
      </Container>
      <EmailModal
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

export default InputBox;
