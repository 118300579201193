import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useSceneStage from "../hooks/useSceneStage";
import backgroundMusic from "../assets/mp3s/background-music.mp3";

const AudioContainer = styled.div`
`;

const BackgroundMusic = () => {
  const audioRef = useRef(null);
  const { isMuted } = useSceneStage();

  useEffect(() => {
    const isAudioPaused = audioRef.current.paused;
    if (isAudioPaused) {
      audioRef.current.play().catch((e) => {
        console.log("e", e);
      })
    }
  }, [isMuted]);

  return (
    <AudioContainer>
      <audio
        ref={audioRef}    
        autoPlay={true}
        preload={"auto"}
        loop={true}
        muted={isMuted}
      >
        <source src={backgroundMusic} type="audio/mp3" />
      </audio>
    </AudioContainer>
  );
};

export default BackgroundMusic;
