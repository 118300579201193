import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import useSceneStage from "../hooks/useSceneStage";
import { device } from "../const/device";

const StyledContentSection = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content-wrapper {
    font-family: "N27";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;

    @media ${device.mobileLMaxWidth} {
      font-size: 12px;
    }
  }
`;

const useArrayRef = () => {
  // create a refs array and initialize it to an empty array
  const refs = useRef([]);
  refs.current = [];
  // then push all the ref in the array
  return [refs, (ref) => ref && refs.current.push(ref)];
};

const TextFadeInEffect = ({ text }) => {
  const wholeTextRef = useRef();
  const [refs, setRef] = useArrayRef();
  const { setNextStage } = useSceneStage();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const splitTextTween = gsap.timeline({});
    splitTextTween.fromTo(
      refs.current,
      {
        autoAlpha: 0,
        display: "inline-block",
      },
      {
        autoAlpha: 1,
        delay: 0.2,
        display: "inline-block",
        duration: 2,
        ease: "back.inOut",
        stagger: 0.2,
      }
    );

    const wholeTextTween = gsap.timeline({});
    wholeTextTween.fromTo(
      wholeTextRef.current,
      {
        autoAlpha: 1,
        display: "inline-block",
      },
      {
        autoAlpha: 0,
        delay: 2,
        display: "inline-block",
        duration: 2,
        ease: "back.inOut",
        stagger: 0.2,
        onComplete: () => {
          setLoaded(true);
        },
      }
    );
  }, [refs]);

  useEffect(() => {
    if (loaded) {
      setNextStage();
    }
  }, [loaded]);

  let words = text.split(" ");

  return (
    <StyledContentSection>
      <div ref={wholeTextRef} className="content-wrapper">
        {words.map((word, i) => {
          return (
            <span key={text + i}>
              <span
                ref={setRef}
                style={{
                  willChange: "transform",
                  color: i !== words.length - 1 ? "white" : "#2DFFD6",
                }}
              >
                {word + (i !== words.length - 1 ? "\u00A0" : "")}
              </span>
            </span>
          );
        })}
      </div>
    </StyledContentSection>
  );
};

export default TextFadeInEffect;
