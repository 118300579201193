import { GridHelper } from "three";
import { Root } from "../Root";
import { MeshStandardMaterial, Object3D } from "three";
import { EventBus } from "../EventDispatcher";

export class Comet {
  constructor() {
    this.mesh = Root.assetsManager.get("comet");

    this.mesh.traverse((child) => {
      if (child.isMesh) {
        child.material = new MeshStandardMaterial({
          roughness: 1,
          metalness: 1,
          envMapIntensity: Root.settings.starsLightIntensity,
          map: Root.assetsManager.get("diffuse"),
          metalnessMap: Root.assetsManager.get("metalness"),
          normalMap: Root.assetsManager.get("normal"),
          roughnessMap: Root.assetsManager.get("roughness"),
        });
        child.scale.setScalar(.5);
        child.userData.affectedByExposure = true;
      }
    });

    if (Root.settings.devMode) {
      Root.scene.add(
        new GridHelper(100, 100)
      );
    }

    this.meshRock = Root.assetsManager.get("rock");
    this.meshRock.traverse((child) => {
      if (child.isMesh) {
        child.material = new MeshStandardMaterial({
          roughness: 1,
          metalness: 1,
          map: Root.assetsManager.get("rockDiffuse"),
          metalnessMap: Root.assetsManager.get("rockMetalness"),
          normalMap: Root.assetsManager.get("rockNormal"),
          roughnessMap: Root.assetsManager.get("rockRoughness"),
        });
        child.scale.setScalar(0.02);
        child.userData.affectedByExposure = true;
      }
    });
    
    this.mesh.rotation.x = Math.PI * 0.25;
    
    // Satellite orbit
    this.cometSystem = new Object3D();
    this.satelliteOrbitArr = [];
    this.createSatelliteOrbit(5);

    this.cometOrbit = new Object3D();
    this.cometOrbit.add(this.mesh);
    this.cometSystem.add(this.cometOrbit);

    Root.scene.add(this.cometSystem);

    EventBus.on("frame", this.onFrame);
  }

  onFrame = ({ seconds, ds }) => {

    const cometRotationSpeed =
      Root.settings && Root.settings.comet && Root.settings.comet.rotationSpeed
        ? Root.settings.comet.rotationSpeed
        : 1.0; 

    this.mesh.rotation.y += ds * cometRotationSpeed;
    
    const satelliteRotationSpeed =
      Root.settings &&
      Root.settings.comet &&
      Root.settings.satellite.rotationSpeed
        ? Root.settings.satellite.rotationSpeed
        : 1.0; 

    this.satelliteOrbitArr.forEach((satellite) => {
      satellite.children[0].position.set(
        Math.cos(seconds) * satellite.children[0].userData.speed,
        Math.sin(seconds) * satellite.children[0].userData.speed,
        0
      );
      satellite.children[0].rotation.x += ds * satelliteRotationSpeed;
      satellite.children[0].rotation.y += ds * satelliteRotationSpeed;
    });
  };

  createSatelliteOrbit(count) {
    for (let index = 0; index < count; index++) {
      const satelliteOrbit = new Object3D();
      const satelliteRock = this.meshRock.clone();

      satelliteRock.position.set(Math.random() + 10, 0, 0);
      satelliteRock.rotation.x = Math.random();   
      
      const satelliteScale = Math.floor(Math.random() * 2) + 1;
      satelliteRock.scale.setScalar(.1 * satelliteScale);
      satelliteRock.userData.speed = (Math.random() + 0.5) * 7;        

      satelliteOrbit.add(satelliteRock);

      satelliteOrbit.rotation.x = (Math.PI / 2) * Math.random() * 5;
      satelliteOrbit.rotation.y = (Math.PI / 2) * Math.random() * 10;
      satelliteOrbit.rotation.z = (Math.PI / 2) * Math.random() * 3;

      this.cometSystem.add(satelliteOrbit);
      this.satelliteOrbitArr.push(satelliteOrbit);
    }
  }
}

