import { useEffect, useRef } from "react";
import { SpriteAnimator } from "react-sprite-animator";
import EmailSpritesheet from "../assets/pngs/email-spritesheet.png";
import styled from "styled-components";
import emailModalBackground from "../assets/pngs/email-modal-background.png";
import emailConfirmBtnBg from "../assets/pngs/email-confirm-btn-bg.png";
import emailConfirmBtnHoverBg from "../assets/pngs/email-confirm-btn-hover-bg.png";
import { device } from "../const/device";

const Container = styled.div`
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  transition: all 150ms ease-in-out;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  width: 456px;
  height: 532px;
  background-image: url(${emailModalBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Header1 = styled.div`
  position: absolute;
  top: 51px;
  margin: 0 auto;
  width: 293px;
  height: 35px;
  font-family: "SquaresFunction-Normal";
  font-style: normal;
  font-weight: 500;
  font-size: 38.9225px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  color: #2dffd6;

  @media ${device.mobileLMaxWidth} {
    top: 81px;
    font-size: 24px;
  }
`;

const Header2 = styled.div`
  position: absolute;
  top: 77px;
  margin: 0 auto;
  width: 290px;
  height: 33px;
  font-family: "SquaresFunction-Outline";
  font-weight: 500;
  font-size: 36px;
  line-height: 33px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
  color: #2dffd6;

  @media ${device.mobileLMaxWidth} {
    top: 120px;
    font-size: 24px;
  }
`;

const Header3 = styled.div`
  position: absolute;
  top: 121px;
  margin: 0 auto;
  width: 295px;
  height: 38px;
  font-family: "N27";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #2dffd6;

  @media ${device.mobileLMaxWidth} {
    top: 180px;
    font-size: 12px;
  }
`;

const Header4 = styled.div`
  position: absolute;
  bottom: 140px;
  width: 295px;
  height: 38px;
  font-family: "N27";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background: linear-gradient(
    92.01deg,
    #ffffff -1.62%,
    rgba(255, 255, 255, 0.63) 34.79%,
    #ffffff 102.43%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media ${device.mobileLMaxWidth} {
    bottom: 160px;
    font-size: 12px;
  }
`;

const ConfirmButton = styled.div`
  position: absolute;
  bottom: 38px;
  width: 189px;
  height: 45px;
  cursor: pointer;
  font-family: "N27";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  /* Theme 1 */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2dffd6;
  background-image: url(${emailConfirmBtnBg});

  &:hover {
    background-image: url(${emailConfirmBtnHoverBg});
  }

  @media ${device.mobileLMaxWidth} {
    bottom: 88px;
    font-size: 12px;
  }
`;

const StyledSpriteAnimator = styled(SpriteAnimator)`
  position: absolute;
  bottom: 232px;

  @media ${device.mobileLMaxWidth} {
    bottom: 222px;
  }
`;

const EmailModal = ({ visible, onOk, onCancel }) => {
  const modalRef = useRef();

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (event.target === modalRef.current) {
        onCancel();
      }
    });

    return () => document.removeEventListener("click", () => onCancel());
  }, []);

  return (
    <Container
      ref={modalRef}
      style={{
        display: visible ? "flex" : "none",
      }}
    >
      <ModalContent>
        <Header1>YOU ARE JOINING</Header1>
        <Header2>THE INNER CIRCLE</Header2>
        <Header3>
          BETA testnet, exclusive society and more mysterious perks await
        </Header3>
        <Header4>A confirmation email has been sent to you</Header4>
        <StyledSpriteAnimator
          sprite={EmailSpritesheet}
          width={100}
          height={80}
          frameCount={100}
          direction="horizontal"
          fps={30}
        />
        <ConfirmButton onClick={onOk}>CONFIRM</ConfirmButton>
      </ModalContent>
    </Container>
  );
};

export default EmailModal;
