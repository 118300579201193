import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import useSceneStage from "../hooks/useSceneStage";
import useInterval from "../hooks/useInterval";

const Container = styled.div`
  min-width: 269px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TextContainer = styled.div`
  margin: 0 auto;
  height: 64px;
  .line {
    font-family: "N27";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 20px;
    margin: 0 auto;
  }
`;

const INTERVAL_TIMING = 50;

const TypingMachineEffect = ({ text }) => {
  const containerRef = useRef(null);
  const { setNextStage } = useSceneStage();
  const [loaded, setLoaded] = useState(false);
  const [typingText, setTypingText] = useState("");
  const index = useRef(-1);
  let timer = null;

  timer = useInterval(() => {
    if (index.current < text.split("").length - 1) {
      setTypingText((prev) => {
        index.current = index.current + 1;
        return prev + text.split("")[index.current];
      });
    } else {
      setLoaded(true);
      clearInterval(timer.current);
    }
  }, INTERVAL_TIMING);

  useEffect(() => {
    if (loaded) {
      const fadeOutTimeline = gsap.timeline();
      fadeOutTimeline.fromTo(
        containerRef.current,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          duration: 1,
          ease: "back.inOut",
          stagger: 0.2,
          onComplete: () => {
            setNextStage();
          },
        }
      );
    }
  }, [loaded, setNextStage]);

  return (
    <Container ref={containerRef}>
      <TextContainer>
        <p className="line">{typingText}</p>
      </TextContainer>
    </Container>
  );
};

export default TypingMachineEffect;
