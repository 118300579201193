import { DodecahedronBufferGeometry, MathUtils, MeshPhongMaterial, Quaternion, Vector3 } from "three";
import { Root } from "../../Root";
import { createInstancedMesh } from "../../util/objectSugar";
import { rr } from "../../util/random";

export class Flakes {
  constructor({ direction, minW, maxW, minL, maxL, size }) {
    const nd = new Vector3(...direction).normalize(),
          up = new Vector3(0, 1, 0),
          rotate = new Quaternion().setFromUnitVectors(up, nd);

    Root.scene.add(
      createInstancedMesh({
        geometry: new DodecahedronBufferGeometry(size, 0),
        material: new MeshPhongMaterial({
          color: 0x333333,
          specular: 0xffffff,
          shininess: 30,
        }),
        count: 273,
        position: () => {
          const l = rr (0, 1),
                y = MathUtils.lerp(minL, maxL, l),
                d = MathUtils.lerp(minW, maxW, l),
                phi = rr(0, Math.PI * 2),
                pos = new Vector3(
                  Math.cos(phi) * d,
                  y,
                  Math.sin(phi) * d
                ).applyQuaternion(rotate);
          return pos;
        },
        rotation: () => [rr(0, Math.PI * 2), rr(0, Math.PI * 2), 0],
        userData: {
          affectedByExposure: true,
          dontRenderDepth: true,
        }
      })
    );
  }
}