// eslint-disable-next-line no-unused-vars
import { PMREMGenerator, FloatType, TextureLoader } from "three";
import { Root } from "../../Root";
// eslint-disable-next-line no-unused-vars
import { RGBELoader } from "./RGBELoader";

export class EnvLoader extends TextureLoader {
  constructor(manager) {
    super(manager);
    // this.setDataType(FloatType)
  }

  load = (url, onLoad, onProgress, onError) => {
    super.load(
      url,
      res => {
        const pmremGenerator = new PMREMGenerator(Root.renderPipe.renderer);
        const env = pmremGenerator.fromEquirectangular(res).texture;
        pmremGenerator.dispose();
        onLoad(env);
      },
      onProgress,
      onError,
    );
  };
}