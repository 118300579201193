import {
  BufferGeometry,
  LineBasicMaterial,
  Line,
  BoxGeometry,
  MeshBasicMaterial,
  Mesh,
} from "three";
import { Root } from "../../Root";
import { objectWrap } from "../../util/objectSugar";

export class CurveHelper {
  constructor({ curve, points, pointSize = .5, color = 0xff0000 }) {

    this.meshes = [];
    
    this.meshes.push(
      new Line(
        new BufferGeometry().setFromPoints(curve.getPoints(50)),
        new LineBasicMaterial({ color })
      )
    );

    points.map((position) => {
      this.meshes.push(
        objectWrap({
          object: new Mesh(
            new BoxGeometry(pointSize, pointSize, pointSize),
            new MeshBasicMaterial({ color }),
          ),
          position,
        })
      );
    });

    Root.scene.add(...this.meshes);
  }

  destroy = () => {
    this.meshes.forEach(mesh => {
      Root.scene.remove(mesh);
      mesh.geometry.dispose();
      mesh.material.dispose();
    });
    this.meshes.length = 0;
  };
}
