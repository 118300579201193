import { PerspectiveCamera, Scene } from "three";
import { settings as defaults } from "./data";
import { EventBus } from "./EventDispatcher";
import { Screen } from "./global/Screen";
import { provide as provideUniform } from "./global/Uniforms";
import { AssetsManager } from "./global/AssetsManager";
import { RenderingPipeline } from "./post/RenderingPipeline";

import Stats from "./util/stats.module";

import { CameraController } from "./components/CameraController";
import { Pointer } from "./components/Pointer";
import { Env } from "./components/Env";
import { Comet } from "./components/Comet";
import { Light } from "./components/Light";
import { Tail } from "./components/Tail";
import { ProgressController } from "./components/ProgressController";
import { Exposure } from "./components/Exposure";

export class Root {

  /** @type {AssetsManager} */
  static assetsManager;

  /** @type {Screen} */
  static screen;
  
  /** @type {RenderingPipeline} */
  static renderPipe;
  
  /** @type {PerspectiveCamera} */
  static camera;
  
  static scene = new Scene();

  static settings = null;
  
  /** @type {Root} */
  static #instance = null;

  static get instance() {
    if (Root.#instance !== null) {
      return Root.#instance;
    }
    return new Root();
  }

  constructor(settings) {
    if (Root.#instance !== null) {
      throw "Root already exists";
    }

    Root.settings = Object.assign(defaults, settings);
    
    Root.assetsManager = new AssetsManager();
    Root.screen = new Screen();
    Root.camera = new PerspectiveCamera(45, Root.screen.aspect, .1, 2e3);
    Root.renderPipe = new RenderingPipeline();
    
    Root.#instance = this;

    EventBus.on("frame.raw", this.onFrame);
  }

  create = ({ container }) => {
    Root.container = container;
    Root.screen.setContainer(container);
    Root.renderPipe.setContainer(container);
    
    new Pointer();
    new CameraController();
    new Env();
    new Comet();
    new Tail();
    new Light(Root.settings.light2);
    new Light(Root.settings.light);
    new Exposure();
    new ProgressController();

    if (defaults.devMode) {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);

      window.cam = Root.camera;
      window.f = Root;
    }

    Root.renderPipe.renderer.compile(Root.scene, Root.camera);
  };

  onFrame = time => {
    if (!("prevTime" in this)) {
      this.prevTime = time;
    }
    const dt = Math.min(1e3 / 30, time - this.prevTime);
    const ds = dt * 1e-3;
    const seconds = time * 1e-3;
    this.prevTime = time;

    const times = { time, dt, seconds, ds };

    provideUniform("time", time);
    provideUniform("seconds", seconds);

    EventBus.dispatch("frame", times);

    EventBus.dispatch("beforeRender", times);
    Root.renderPipe.render();
    EventBus.dispatch("afterRender", times);
    
    defaults.devMode && this.stats.update();
  };
}