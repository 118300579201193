import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { SpriteAnimator } from "react-sprite-animator";
import { Player } from "@lottiefiles/react-lottie-player";
import targetElementAnimation from "../../assets/jsons/target-element-animation.json";
import AsteroidSpritesheet from "../../assets/pngs/asteroid-spritesheet.png";
import AsteroidSpritesheetMobile from "../../assets/pngs/asteroid-spritesheet-mobile.png";
import cometDataAnimation from "../../assets/jsons/comet-data-animation.json";
import moduleInfoAnimation from "../../assets/jsons/module-info-animation.json";
import { device, deviceWidthInPixel } from "../../const/device";
import useWindowSize from "../../hooks/useWindowSize";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
`;

const TargetElementAnimation = styled.div`
  position: absolute;
  height: 96px;
  width: 96px;
  z-index: 9999;
  left: 35%;
  top: 50%;
`;

const CometDataAnimation = styled.div`
  position: absolute;
  top: 0px;
  z-index: 9999;
  width: 192px;
  height: 98px;
  top: 23%;
  left: 43%;
`;

const ModuleInfoAnimation = styled.div`
  position: absolute;
  width: 519px;
  z-index: 9999;
  top: 28%;
  right: 10%;
  background: rgba(132, 132, 132, 0.05);
  backdrop-filter: blur(2.48517px);

  @media ${device.mobileLMaxWidth} {
    width: 318px;
    right: 5%;
  }
`;

const StyledSpriteAnimator = styled(SpriteAnimator)`
  position: absolute;
  z-index: 99999;
  top: 41%;
  right: 11.5%;
  @media ${device.mobileLMaxWidth} {
    top: 38%;
    right: 9.5%;
  }
`;

const AsteroidInfoPanel = ({ localStage, setLocalStage }) => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width < deviceWidthInPixel.mobileL) {
      setIsMobile(true);
      setLocalStage((prevStage) => prevStage + 2);
    } else {
      setIsMobile(false);
    }
  }, [width, setLocalStage]);

  return (
    <Container>
      {localStage >= 0 && !isMobile ? (
        <TargetElementAnimation>
          <Player
            onEvent={(event) => {
              if (event === "play") {
                setTimeout(() => {
                  setLocalStage((prevStage) => prevStage + 1);
                }, 500);
              }
            }}
            autoplay
            loop={false}
            keepLastFrame
            controls={false}
            src={targetElementAnimation}
          />
        </TargetElementAnimation>
      ) : null}
      {localStage >= 1 && !isMobile ? (
        <CometDataAnimation>
          <Player
            onEvent={(event) => {
              if (event === "play") {
                setTimeout(() => {
                  setLocalStage((prevStage) => prevStage + 1);
                }, 500);
              }
            }}
            autoplay
            loop={false}
            keepLastFrame
            controls={false}
            src={cometDataAnimation}
          />
        </CometDataAnimation>
      ) : null}
      {localStage >= 2 && (
        <Fragment>
          {isMobile ? (
            <StyledSpriteAnimator
              className={`asteroid-wireframe`}
              sprite={AsteroidSpritesheetMobile}
              width={120}
              height={120}
              frameCount={100}
              direction="horizontal"
              fps={60}
            />
          ) : (
            <StyledSpriteAnimator
              className={`asteroid-wireframe`}
              sprite={AsteroidSpritesheet}
              width={200}
              height={200}
              frameCount={100}
              direction="horizontal"
              fps={60}
            />
          )}
          <ModuleInfoAnimation>
            <Player
              onEvent={(event) => {
                if (event === "play") {
                  setTimeout(() => {
                    setLocalStage((prevStage) => prevStage + 1);
                  }, 1500);
                }
              }}
              autoplay
              loop={false}
              keepLastFrame
              controls={false}
              src={moduleInfoAnimation}
            />
          </ModuleInfoAnimation>
        </Fragment>
      )}
    </Container>
  );
};

export default AsteroidInfoPanel;
