import { AdditiveBlending, BackSide, Color, ShaderMaterial, SphereBufferGeometry } from "three";
import { Root } from "../../Root";
import { bind } from "../../global/Uniforms";
import { createMesh } from "../../util/objectSugar";

import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
import noise from "../../shaderLib/noise.glsl";
import { itm, rr } from "../../util/random";
import { Flakes } from "./Flakes";

function toV3(arr) {
  return `vec3(${arr.map(n => n.toFixed(5)).join(",")})`;
}

export class Tail {
  constructor() {
    const count = 15,
          direction = [1, 1, -1],
          ends = new Array(count).fill(0)
            .map(() => [rr(75, 125), rr(75, 125), rr(75, 125)].map((c, i) => c * direction[i])),
          palette = [0xffffff, 0xff00ff, 0x8888ff, 0x0088ff, 0x0000ff, 0x0000ff, 0x0000ff]
            .map(hex => new Color(hex)),
          colors = new Array(count).fill(0)
            .map(() => itm(palette)),
          sizes = new Array(count).fill(0)
            .map(() => rr(8, 16).toFixed(1));

    ends.unshift([200, 200, -200]);
    colors.unshift(palette[0]);
    sizes.unshift("50.");

    Root.scene.add(
      createMesh({
        geometry: new SphereBufferGeometry(900),
        material: new ShaderMaterial({
          uniforms: {
            time: bind("seconds"),
            lightColor: bind("light.color"),
            exposure: bind("exposure"),
          },
          side: BackSide,
          blending: AdditiveBlending,
          vertexShader,
          fragmentShader: noise + fragmentShader
            .replace("inject_count", count + 1)
            .replace("inject_sizes", `float[count](${sizes.join(",")})`)
            .replace("inject_ends", `vec3[count](${ends.map(arr => toV3(arr)).join(",")})`)
            .replace("inject_colors", `vec3[count](${colors.map(color => toV3(color.toArray())).join(",")})`)
          ,
        }),
        userData: {
          dontRenderDepth: true,
        }
      })
    );

    new Flakes({
      direction,
      minW: 1,
      maxW: 50,
      minL: 10,
      maxL: 500,
      size: .1,
    });
    
  }
}