import { Player } from "@lottiefiles/react-lottie-player";
import styled from "styled-components";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import { ReactComponent as DiscordIcon } from "../../assets/svgs/discord.svg";
import { ReactComponent as TgIcon } from "../../assets/svgs/tg.svg";
import { ReactComponent as TwIcon } from "../../assets/svgs/tw.svg";
import { ReactComponent as LeftSubHeaderIcon } from "../../assets/svgs/left-sub-header-icon.svg";
import { ReactComponent as RightSubHeaderIcon } from "../../assets/svgs/right-sub-header-icon.svg";
import { ReactComponent as Seperator } from "../../assets/svgs/seperator.svg";
import starryBackground from "../../assets/pngs/starry-background.png";
import logoAnimation from "../../assets/jsons/logo-animation.json";
import lineAnimationLeft from "../../assets/jsons/line-animation-left.json";
import lineAnimationRight from "../../assets/jsons/line-animation-right.json";
import lineFooterAnimation from "../../assets/jsons/line-footer-animation.json";
import MusicOnAnimation from "../MusicOnAnimation";
import BackgroundMusic from "../BackgroundMusic";
import useSceneStage from "../../hooks/useSceneStage";
import { device } from "../../const/device";

const StarBg = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url(${starryBackground});
`;

const StyledContentSection = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  height: 100vh;
  touch-action: pan-y; // fix double tap zoom
  -webkit-user-select: none;
  -webkit-touch-callout:none;
  user-select: none;
`;

const IconsContainer = styled.div`
  position: fixed;
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 33px;
  z-index: 9999;

  @media ${device.mobileLMaxWidth} {
    bottom: 48px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 24px;
  svg {
    @media ${device.mobileLMaxWidth} {
      width: 15px;
    }
  }
`;

const MusicBtnContainer = styled.div``;

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 20px;
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileLMaxWidth} {
    top: 40px;
  }
`;

const LeftContainer = styled.div`
  min-width: 538px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileLMaxWidth} {
    min-width: 300px;
  }
`;

const LogoContainer = styled.div`
  min-width: 281px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileLMaxWidth} {
    min-width: 133px;
  }
`;

const RightContainer = styled.div`
  min-width: 538px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileLMaxWidth} {
    min-width: 300px;
  }
`;

const LeftSubHeaderContainer = styled.div`
  width: 100%;
  margin: 2px 12% 0 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SubHeaderIconContainer = styled.div`
  svg {
    @media ${device.mobileLMaxWidth} {
      height: 10px;
    }
  }
`;

const SubHeaderContent = styled.span`
  margin: 0 12px;
  font-family: "N27";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
`;

const RightSubHeaderContainer = styled.div`
  width: 100%;
  margin: 2px 0 0 12%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SeperatorContainer = styled.div`
  display: flex;
  margin: 0 18px;
`;

const LeftContentContainer = styled.div`
  display: none;
  align-items: center;
`;

const RightContentContainer = styled.div`
  display: none;
  align-items: center;
`;

const HeaderLines = () => {
  const { setStage } = useSceneStage();
  const leftSubHeaderRef = useRef();
  const rightSubHeaderRef = useRef();
  const leftContentRef = useRef();
  const rightContentRef = useRef();

  const leftSubHeaderSlideIn = gsap.timeline({ paused: true });
  const rightSubHeaderSlideIn = gsap.timeline({ paused: true });

  const slideInFrom = {
    display: "none",
  };
  const slideInTo = {
    display: "flex",
  };

  leftSubHeaderSlideIn.fromTo(leftContentRef.current, slideInFrom, slideInTo);
  rightSubHeaderSlideIn.fromTo(rightContentRef.current, slideInFrom, slideInTo);

  const handleLeftMouseEnter = () => {
    // todo: temporary hide
    // leftSubHeaderSlideIn.play();
  };

  const handleLeftMouseLeave = () => {
    // todo: temporary hide
    // leftSubHeaderSlideIn.reverse();
  };

  const handleRightMouseEnter = () => {
    // todo: temporary hide
    // rightSubHeaderSlideIn.play();
  };

  const handleRightMouseLeave = () => {
    // todo: temporary hide
    // rightSubHeaderSlideIn.reverse();
  };

  useEffect(() => {
    const leftSubHeaderTween = gsap.timeline();
    const rightSubHeaderTween = gsap.timeline({
      onComplete: () => {
        setStage(1);
      },
    });

    const fadeFrom = {
      autoAlpha: 0,
    };
    const fadeTo = {
      autoAlpha: 1,
      delay: 0.2,
      duration: 2,
      ease: "back.inOut",
      stagger: 0.2,
    };

    leftSubHeaderTween.fromTo(leftSubHeaderRef.current, fadeFrom, fadeTo);

    rightSubHeaderTween.fromTo(rightSubHeaderRef.current, fadeFrom, fadeTo);
  }, []);

  return (
    <HeaderContainer>
      <LeftContainer
        onMouseEnter={handleLeftMouseEnter}
        onMouseLeave={handleLeftMouseLeave}
      >
        <Player
          autoplay
          loop={false}
          keepLastFrame
          controls={false}
          src={lineAnimationLeft}
        />
        <LeftSubHeaderContainer ref={leftSubHeaderRef}>
          <SubHeaderIconContainer>
            <LeftSubHeaderIcon />
          </SubHeaderIconContainer>
          <LeftContentContainer ref={leftContentRef}>
            <SubHeaderContent>PREAMBLE</SubHeaderContent>
            <SeperatorContainer>
              <Seperator />
            </SeperatorContainer>
            <SubHeaderContent>WHITEPAPER</SubHeaderContent>
          </LeftContentContainer>
        </LeftSubHeaderContainer>
      </LeftContainer>
      <LogoContainer>
        <Player
          autoplay
          loop={false}
          keepLastFrame
          controls={false}
          src={logoAnimation}
        />
      </LogoContainer>
      <RightContainer
        onMouseEnter={handleRightMouseEnter}
        onMouseLeave={handleRightMouseLeave}
      >
        <RightSubHeaderContainer ref={rightSubHeaderRef}>
          <RightContentContainer ref={rightContentRef}>
            <SubHeaderContent>SIGN UP</SubHeaderContent>
          </RightContentContainer>
          <SubHeaderIconContainer>
            <RightSubHeaderIcon />
          </SubHeaderIconContainer>
        </RightSubHeaderContainer>
        <Player
          autoplay
          loop={false}
          keepLastFrame
          controls={false}
          src={lineAnimationRight}
        />
      </RightContainer>
    </HeaderContainer>
  );
};

const FooterContainer = styled.div`
  position: fixed;
  bottom: 45px;
  width: 100%;
  height: 21px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileLMaxWidth} {
    bottom: 68px;
    padding: 0 48px;
  }
`;

const FooterLines = () => (
  <FooterContainer>
    <Player
      autoplay
      loop={false}
      keepLastFrame
      controls={false}
      src={lineFooterAnimation}
      style={{
        height: "21px",
      }}
    />
  </FooterContainer>
);

const ScreenLayout = ({ children }) => {
  const iconsRef = useRef();
  const musicBtnRef = useRef();

  useEffect(() => {
    const iconTween = gsap.timeline();
    const musicBtnTween = gsap.timeline();
    const fadeFrom = {
      autoAlpha: 0,
      display: "inline-block",
    };
    const fadeTo = {
      autoAlpha: 1,
      delay: 0.2,
      display: "inline-block",
      duration: 2,
      ease: "back.inOut",
      stagger: 0.2,
    };

    iconTween.fromTo(iconsRef.current, fadeFrom, fadeTo);

    musicBtnTween.fromTo(musicBtnRef.current, fadeFrom, fadeTo);
  }, []);

  const handleIconClick = () => {
    window.open("https://twitter.com/playdebris");
  };

  return (
    <StyledContentSection>
      <StarBg />
      <HeaderLines />
      <BackgroundMusic />
      {children}
      <IconsContainer ref={iconsRef}>
        <IconContainer>
          <TgIcon />
        </IconContainer>
        <IconContainer onClick={handleIconClick}>
          <TwIcon />
        </IconContainer>
        <IconContainer>
          <DiscordIcon />
        </IconContainer>
      </IconsContainer>
      <MusicBtnContainer ref={musicBtnRef}>
        <MusicOnAnimation />
      </MusicBtnContainer>
      <FooterLines />
    </StyledContentSection>
  );
};

export default ScreenLayout;
