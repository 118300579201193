import { Fragment, useState, useEffect, useRef } from "react";
import TextFadeInEffect from "./components/TextFadeInEffect";
import TypingMachineEffect from "./components/TypingMachineEffect";
import ScrollingConfirm from "./components/ScrollingConfirm";
import AsteroidScreen from "./components/AsteroidScreen";
import SignUpAnimation from "./components/SignUpAnimation";
import MouseScrollAnimation from "./components/MouseScrollAnimation";
import LoadingScreen from "./components/LoadingScreen";
import ScreenLayout from "./components/ScreenLayout";
import FadeInVideo from "./components/FadeInVideo";
import BackgroundVideo from "./components/BackgroundVideo";
import InputBox from "./components/InputBox";
import IntroSreen from "./components/IntroSreen";
import useSceneStage from "./hooks/useSceneStage";
import styled from "styled-components";

const CanvasContainer = styled.div`
  touch-action: pan-y; // fix double tap zoom
  -webkit-user-select: none;
  -webkit-touch-callout:none;
  user-select: none;
  width: 100%;
  height: 100%;
`

function App() {
  const renderRef = useRef(true);
  const { stage, setPrevStage, setNextStage } = useSceneStage();
  const [firstScreenLoadedResNum, setFirstScreenLoadedResNum] = useState(0);

  const prevStage = () => {
    setPrevStage();
  };

  const nextStage = () => {
    setNextStage();
  };

  const renderStage = () => {
    return (
      <Fragment>
        {stage === 1 ? (
          <LoadingScreen firstScreenLoadedResNum={firstScreenLoadedResNum} />
        ) : null}
        {stage === 2 ? <IntroSreen /> : null}
        {stage === 3 ? <ScrollingConfirm /> : null}
        <CanvasContainer
          style={{ visibility: [4].includes(stage) ? "visible" : "hidden" }}
        >
          <AsteroidScreen />
          <MouseScrollAnimation />
        </CanvasContainer>
        {process.env.NODE_ENV === "development" && (
          <Fragment>
            <button
              style={{
                position: "absolute",
                top: "1px",
                zIndex: 99999,
                cursor: "pointer",
              }}
              onClick={prevStage}
            >
              prevStage
            </button>
            <button
              style={{
                position: "absolute",
                top: "1px",
                left: "200px",
                zIndex: 99999,
                cursor: "pointer",
              }}
              onClick={nextStage}
            >
              nextStage
            </button>
          </Fragment>
        )}
        {stage === 5 ? (
          // <TextFadeInEffect text={"Now LILITH has landed among us..."} />
          <TypingMachineEffect text={"NOW LILITH HAS LANDED AMONG US..."} />
        ) : null}
        {stage === 6 ? (
          <Fragment>
            <FadeInVideo />
          </Fragment>
        ) : null}
        {stage === 7 ? (
          <Fragment>
            <BackgroundVideo />
            <SignUpAnimation />
            <InputBox />
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  useEffect(() => {
    // in case twice rendering caused by React Strict Mode
    if (renderRef.current) {
      renderRef.current = false;
      const INITIATOR_TYPE = ["img", "fetch"];
      const STATIC_RESOURCE_TYPE = ["jpg", "obj"];
      const resourceSet = new Set();
      new PerformanceObserver((entryList) => {
        // 任意资源加载完成基本都会回调（极少数情况不会，可忽略）
        entryList.getEntries().forEach((entry) => {
          // 我们可以通过 entry.name 后缀或 entry.initiatorType 来判断资源类型
          const fileName = entry.name;
          const fileExtension = fileName.split(".").pop().toLowerCase();
          const fileInitiatorType = entry.initiatorType;
          if (
            INITIATOR_TYPE.includes(fileInitiatorType) &&
            STATIC_RESOURCE_TYPE.includes(fileExtension)
          ) {
            if (!resourceSet.has(fileName)) {
              resourceSet.add(fileName);
            }
            setFirstScreenLoadedResNum(resourceSet.size);
          }
        });
      }).observe({ entryTypes: ["resource"] });
    }
  }, []);

  return (
    <>
      <ScreenLayout>{renderStage()}</ScreenLayout>
    </>
  );
}

export default App;
