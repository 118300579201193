import React, { useState, useEffect, useRef } from "react";
// import FindLilith from "../assets/videos/FindLilith.mp4";
import styled from "styled-components";
import { gsap } from "gsap";
import useSceneStage from "../hooks/useSceneStage";
import useWindowSize from "../hooks/useWindowSize";
import { deviceWidthInPixel } from "../const/device";

const VideoContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const HeaderShadow = styled.div`
  position: fixed;
  width: 100%;
  height: 211px;
  top: 0px;
  background: linear-gradient(180deg, #0d0d0d 50.31%, rgba(13, 13, 13, 0) 100%);
  z-index: 999;
`;

const FooterShadow = styled.div`
  position: fixed;
  height: 217px;
  width: 100%;
  bottom: 0px;
  background: linear-gradient(180deg, #0d0d0d 50.31%, rgba(13, 13, 13, 0) 100%);
  transform: rotate(-180deg);
  z-index: 999;
`;

const BackgroundVideo = () => {
  const triggerRef = useRef();
  const { videoSrc } = useSceneStage();
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width < deviceWidthInPixel.mobileL) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    const splitTextTween = gsap.timeline({});
    splitTextTween.fromTo(
      triggerRef.current,
      {
        autoAlpha: 0,
        display: "inline-block",
      },
      {
        autoAlpha: 1,
        display: "inline-block",
        duration: 1,
        ease: "back.inOut",
      }
    );
  }, [triggerRef]);

  return (
    <VideoContainer ref={triggerRef}>
      {isMobile ? <HeaderShadow></HeaderShadow> : null}
      <video
        autoPlay={true}
        preload={"auto"}
        loop={true}
        muted={true}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          filter: "blur(15px)",
          transform: "scale(1.06)",
        }}
        src={videoSrc}
        playsInline
        type="video/mp4"
      ></video>
      {isMobile ? <FooterShadow></FooterShadow> : null}
    </VideoContainer>
  );
};

export default BackgroundVideo;
