import { MathUtils, Vector3 } from "three";
import { EventBus } from "../EventDispatcher";
import { bind, provide } from "../global/Uniforms";
import { Root } from "../Root";

export class Exposure {
  constructor() {
    this.initialDSq = new Vector3(...Root.settings.camera.positions[0]).lengthSq();

    Root.scene.traverse(child => {
      if (child.isMesh && child.userData.affectedByExposure) {
        child.material.onBeforeCompile = shader => {
          shader.uniforms.exposure = bind("exposure");
          shader.fragmentShader = shader.fragmentShader.replace(
            "#include <dithering_fragment>",
            "gl_FragColor = vec4(outgoingLight * exposure, diffuseColor.a);"
          ).replace(
            "void main() {",
            "uniform float exposure; void main() {"
          );
        };
      }
    });

    EventBus.on("frame", this.onFrame);
  }

  onFrame = () => {
    provide("exposure", MathUtils.lerp(1, 20, Root.camera.position.lengthSq() / this.initialDSq));
  };
}