import { EventBus } from "./EventDispatcher";
import { Root } from "./Root";

export class GL {
  constructor(settings) {
    new Root(settings);
  }

  load = () => {
    EventBus.on("loading.complete", this.onLoad);
    
    Root.assetsManager.load();

    return new Promise((res) => {
      this.loadingResolve = res;
    });
  };

  onLoad = () => {
    this.loadingResolve();
  }

  create = ({ container }) => {
    Root.instance.create({ container });
  
    document.body.addEventListener("mousemove", e => {
      EventBus.dispatch("pointer.raw", {
        x: e.clientX,
        y: e.clientY,
      });
    });

    requestAnimationFrame(this.onFrame);
  };

  updatePointer = ({ x, y }) => {
    EventBus.dispatch("pointer.raw", { x, y });
  };

  onFrame = (time) => {
    EventBus.dispatch("frame.raw", time);
    
    requestAnimationFrame(this.onFrame);
  };

  setProgress = (progress) => {
    EventBus.dispatch("progress.raw", progress);
  };
  
}