import { DirectionalLight } from "three";
import { Root } from "../Root";
// import { guiField } from "../global/GUI";
// import { settings as defaults } from "../data";
import { objectWrap } from "../util/objectSugar";
import { provide } from "../global/Uniforms";

export class Light {
  constructor(settings) {
    this.light = objectWrap({
      object: new DirectionalLight(settings.color, settings.intensity),
      position: settings.position,
    });

    provide("light.color", this.light.color);
    Root.scene.add(this.light);

    // defaults.devMode && guiField(this.light, "intensity", 0, 5);
  }
}
