import { bind, provide } from "../../../global/Uniforms";
import { EventBus } from "../../../EventDispatcher";
import { Root } from "../../../Root";
import { RenderingPipeline } from "../../RenderingPipeline";
import {
  ShaderMaterial,
  WebGLRenderTarget,
  MeshDepthMaterial,
  RGBADepthPacking,
  Vector2,
  SplineCurve,
} from "three";

import vertexShader from "./dof.vert";
import fragmentShader from "./dof.frag";

export class DoF {

  constructor(capabilities) {
    let resX = Root.screen.x;
    let resY = Root.screen.y;

    this.depthMaterial = new MeshDepthMaterial();
    this.depthMaterial.depthPacking = RGBADepthPacking;

    this.depthRenderTarget = new WebGLRenderTarget(resX, resY, RenderingPipeline.rtParameters);
    this.renderTarget = new WebGLRenderTarget(resX, resY, RenderingPipeline.rtParameters);

    this.material = new ShaderMaterial({
      defines: {
        DEPTH_PACKING: 1,
        PERSPECTIVE_CAMERA: 1,
        WEBGL2: capabilities.isWebGL2 ? 1 : 0,
      },
      uniforms: {
        map: bind("post.screen"),
        depthMap: { value: this.depthRenderTarget.texture },
        pixelSize: { value: Root.screen.iv2 },
        aspect: { value: Root.screen.aspectIV2 },
        focus: bind("post.dof.focus", 10),
        blurDistance: bind("post.dof.blurDistance", 0),
        maxBlur: bind("post.dof.maxBlur", 0.4),
        nearClip: { value: Root.camera.near },
        farClip: { value: Root.camera.far },
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    this.blurDistanceCurve = arrayToSplineCurve(Root.settings.DoF.blurDistance);
    this.maxBlurCurve = arrayToSplineCurve(Root.settings.DoF.maxBlur);
    this.focusShiftCurve = arrayToSplineCurve(Root.settings.DoF.focusShift);
    this.focusShift = 0;

    EventBus.on("frame", this.onFrame);
    EventBus.on("progress", this.onProgress);
  }

  onProgress = progress => {
    const blurDistancePoint = this.blurDistanceCurve.getPoint(progress);
    const maxBlurPoint = this.maxBlurCurve.getPoint(progress);
    const focusShiftPoint = this.focusShiftCurve.getPoint(progress);
    provide("post.dof.blurDistance", Math.max(0, blurDistancePoint.x));
    provide("post.dof.maxBlur", Math.max(0, maxBlurPoint.x));
    this.focusShift = focusShiftPoint.x;
  };

  onFrame = () => {
    const camDistance = Root.camera.position.length();
    provide("post.dof.focus", camDistance + this.focusShift);
  };

  render = (renderer, quad, camera) => {
    Root.scene.traverse((child) => {
      if (child.isMesh) {
        if (child.userData.dontRenderDepth) {
          child.userData.originalVisible = child.visible;
          child.visible = false;
        } else {
          child.userData.originalMaterial = child.material;
          child.material = this.depthMaterial;
        }
      }
    });

    renderer.setRenderTarget(this.depthRenderTarget);
    renderer.render(Root.scene, camera);

    Root.scene.traverse((child) => {
      if (child.isMesh) {
        if (child.userData.dontRenderDepth) {
          child.visible = child.userData.originalVisible;
        } else {
          child.material = child.userData.originalMaterial;
        }
      }
    });

    quad.material = this.material;
    renderer.setRenderTarget(this.renderTarget);
    renderer.render(quad, camera);

    provide("post.dof.raw", this.renderTarget.texture);
    provide("post.screen", this.renderTarget.texture);
  };
}

function arrayToSplineCurve(arr) {
  let arrForSpline = arr.map((element) => {
    return new Vector2(element, 0);
  });

  return new SplineCurve(arrForSpline);
}   

 