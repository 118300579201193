import { Player } from "@lottiefiles/react-lottie-player";
import musicOnAnimation from "../assets/jsons/music-on-animation.json";
import { ReactComponent as MusicOffSvg } from "../assets/svgs/music-off.svg";
import styled from "styled-components";
import useSceneStage from "../hooks/useSceneStage";
import { device } from "../const/device";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  height: 38px;
  position: fixed;
  bottom: 60px;
  left: 90%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  cursor: pointer;

  @media ${device.mobileLMaxWidth} {
    bottom: 90px;
    min-width: 48px;
    svg {
      width: 48px;
    }
  }
`;

const MusicOnAnimation = () => {
  const { isMuted, toggleIsMuted } = useSceneStage();

  return (
    <Container onClick={toggleIsMuted}>
      {isMuted ? (
        <MusicOffSvg />
      ) : (
        <Player
          autoplay
          loop={true}
          keepLastFrame={true}
          controls={false}
          src={musicOnAnimation}
        />
      )}
    </Container>
  );
};

export default MusicOnAnimation;
