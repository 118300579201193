import { useState, useEffect } from "react";
import styled from "styled-components";
import segmentLoadingBar from "../../assets/pngs/segment-loading-bar.png";
import { device } from "../../const/device";
import { Animate } from "react-move";
import { easeExpOut } from "d3-ease";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressBar = styled.div`
  position: relative;
  max-width: 350px;
  width: 350px;
  bottom: 20px;
  margin: 0 auto 0;
  height: 23px;
  overflow: hidden;

  @media ${device.mobileLMaxWidth} {
    width: 200px;
  }
`;

const Progress = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0px 0px 12px 1px #2dffd6;
  margin: 10px 0;
  transition: all 0.3s;
  background-image: url(${segmentLoadingBar});
`;

const ProgressText = styled.p`
  font-family: "N27";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 12px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
`;

const TOTAL_RESOURCE_NUM = [
  "/textures/env.jpg",
  "/textures/comet/diffuse.jpg",
  "/textures/comet/metalness.jpg",
  "/textures/comet/normals.jpg",
  "/textures/rock/metalness.jpg",
  "/textures/comet/roughness.jpg",
  "/textures/rock/normals.jpg",
  "/textures/rock/diffuse.jpg",
  "/textures/rock/roughness.jpg",
  "/models/comet.obj",
  "/models/rock.obj",
].length;

const CustomLoading = ({ firstScreenLoadedResNum, setComplete }) => {
  const [progressState, setProgressState] = useState(0);

  useEffect(() => {
    setProgressState(firstScreenLoadedResNum);
  }, [firstScreenLoadedResNum]);

  return (
    <Container>
      <Animate
        start={() => ({
          progressState: 0,
        })}
        update={() => ({
          progressState: [progressState],
          timing: { duration: 1000, ease: easeExpOut },
          events: {
            end: () => {
              if (progressState === TOTAL_RESOURCE_NUM) {
                setComplete();
              }
            },
          },
        })}
      >
        {(state) => {
          const { progressState } = state;
          const percent = Math.floor(
            (progressState / TOTAL_RESOURCE_NUM) * 100
          );
          return (
            <>
              <ProgressBar>
                <Progress
                  style={{
                    width: `${percent}%`,
                    transform: "translate3d(0px,0px,0px)",
                  }}
                />
              </ProgressBar>

              <ProgressText>{percent} %</ProgressText>
            </>
          );
        }}
      </Animate>
    </Container>
  );
};

export default CustomLoading;
