import { EventBus } from "../EventDispatcher";
import { provide } from "../global/Uniforms";

export class ProgressController {
  constructor() {
    EventBus.on("progress.raw", this.onProgress);
    this.onProgress(0);
  }

  onProgress = raw => {
    const progress = Math.max(0, Math.min(1, raw * .5));
    provide("progress", progress);
    EventBus.dispatch("progress", progress);
  };
}
