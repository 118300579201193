import { Vector2, AxesHelper, Object3D, Euler } from "three";
import { settings as defaults } from "../../data";
import { EventBus } from "../../EventDispatcher";
import { Root } from "../../Root";
import { CurveHelper } from "./CurveHelper";
import { OrbitControls } from "./OrbitControls";
import { ProgressCurve } from "./ProgressCurve";

export class CameraController {
  constructor() {
    this.onResize(Root.screen);
    
    const settings = Root.settings.camera;
    this.curvePosition = new ProgressCurve(settings.positions);
    this.curveTarget = new ProgressCurve(settings.targets);

    this.lookAround = new Vector2();
    this.lookAroundTarget = new Vector2();
    this.lookAroundDamping = .1;

    this.transform = new Object3D();
    this.transform.isCamera = true;
    this.lookAroundEuler = new Euler();

    if (defaults.devMode) {
      this.controls = new OrbitControls(Root.camera, Root.container);
      this.controls.target.setScalar(0);

      new CurveHelper({ 
        curve: this.curvePosition,
        points: settings.positions,
      });
      new CurveHelper({ 
        curve: this.curveTarget,
        points: settings.targets,
        color: 0x00ff00,
      });
      Root.scene.add(new AxesHelper(10));

      window.controls = this.controls;
      window.getCam = () => {
        console.log(`
[${Root.camera.position.toArray().join(", ")}],
[${this.controls.target.toArray().join(", ")}],
        `);
      };
    }

    this.onProgress(0);

    EventBus.on("resize", this.onResize);
    EventBus.on("frame", this.onFrame);
    EventBus.on("progress", this.onProgress);
    EventBus.on("pointer", this.onPointer);
  }

  onPointer = ({ x, y }) => {
    this.lookAroundTarget.x = -x * Math.PI * .025;
    this.lookAroundTarget.y = y / Root.screen.aspect * Math.PI * .025;
  };

  onFrame = () => {
    this.lookAround.x += (this.lookAroundTarget.x - this.lookAround.x) * this.lookAroundDamping;
    this.lookAround.y += (this.lookAroundTarget.y - this.lookAround.y) * this.lookAroundDamping;

    this.lookAroundEuler.set(this.lookAround.y, this.lookAround.x, 0);
    Root.camera.quaternion.setFromEuler(this.lookAroundEuler);
    Root.camera.quaternion.multiply(this.transform.quaternion);
  };

  onProgress = (progress) => {
    const position = this.curvePosition.getP(progress);
    this.transform.position.copy(position);
    Root.camera.position.copy(position);
    
    const target = this.curveTarget.getP(progress);
    this.transform.lookAt(target);

    this.controls && this.controls.target.copy(target);
  };

  onResize = ({ aspect }) => {
    Root.camera.aspect = aspect;
    Root.camera.updateProjectionMatrix();
  };
}