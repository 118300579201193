export const deviceWidthInPixel = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const device = {
  mobileSMaxWidth: `(max-width: ${deviceWidthInPixel.mobileS}px)`,
  mobileMMaxWidth: `(max-width: ${deviceWidthInPixel.mobileM}px)`,
  mobileLMaxWidth: `(max-width: ${deviceWidthInPixel.mobileL}px)`,
  tabletMaxWidth: `(max-width: ${deviceWidthInPixel.tablet}px)`,
  laptopMaxWidth: `(max-width: ${deviceWidthInPixel.laptop}px)`,
  laptopLMaxWidth: `(max-width: ${deviceWidthInPixel.laptopL}px)`,
  desktopMaxWidth: `(max-width: ${deviceWidthInPixel.desktop}px)`,
  desktopLMaxWidth: `(max-width: ${deviceWidthInPixel.desktop}px)`,
};
