import { BackSide, ShaderMaterial, SphereBufferGeometry } from "three";
import { Root } from "../Root";
import { createMesh } from "../util/objectSugar";

export class Env {
  constructor() {
    Root.scene.add(
      createMesh({
        geometry: new SphereBufferGeometry(1e3),
        material: new ShaderMaterial({
          uniforms: {
            map: { value: Root.assetsManager.get("envMap") },
          },
          side: BackSide,
          vertexShader: /*glsl*/`
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.);
            }
          `,
          fragmentShader: /*glsl*/`
            varying vec2 vUv;
            uniform sampler2D map;
            void main() {
              vec3 color = texture2D(map, vUv).rgb;
              // color = color * vec3(2., 2., 2.) - vec3(1., 1., .5);
              color = pow(color, vec3(2., 2., 1.75));
              gl_FragColor = vec4(clamp(color, 0., 1.), 1.);
            }
          `
        }),
      })
    );

    Root.scene.environment = Root.assetsManager.get("env");
  }
}