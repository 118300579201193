import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import debrisIntroTextDescription from "../assets/jsons/debris-intro-text-description.json";
import enterStoryBtnBg from "../assets/pngs/enter-story-btn-bg.png";
import styled from "styled-components";
import useSceneStage from "../hooks/useSceneStage";
import useInterval from "../hooks/useInterval";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TitleContainer = styled.div`
  margin: 0 auto;
  height: 64px;
  .line {
    font-family: "N27";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 20px;
    margin: 0 auto;
  }
`;

const DescriptionContainer = styled.div`
  margin: 20px auto 0 auto;
  width: 288px;
`;

const ButtonContainer = styled.div`
  margin: 100px auto 0 auto;
  cursor: pointer;
  width: 189px;
  height: 45.75px;
  font-family: "N27";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${enterStoryBtnBg});

  .text-container {
    color: #ffffff;
  }

  &:hover .text-container {
    color: #2dffd6;
    text-shadow: 1px 1px 8px #2dffd6;
  }
`;

const INTRO_TEXT_ARRAY =
  "DEBRIS is an immersive geolocation AR game, redefining web3 gaming as we know it.".split(
    ""
  );

const INTERVAL_TIMING = 50;

const IntroSreen = () => {
  const btnRef = useRef();
  const { setNextStage, setIsMuted } = useSceneStage();
  const [typingText, setTypingText] = useState("");
  const [isNextVisible, setIsNextVisible] = useState(false);
  const index = useRef(-1);
  let timer = null;

  timer = useInterval(() => {
    if (index.current < INTRO_TEXT_ARRAY.length - 1) {
      setTypingText((prev) => {
        index.current = index.current + 1;
        return prev + INTRO_TEXT_ARRAY[index.current];
      });
    } else {
      setIsNextVisible(true);
      clearInterval(timer.current);
    }
  }, INTERVAL_TIMING);

  useEffect(() => {
    const timeline = gsap.timeline();
    timeline.fromTo(
      btnRef.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 2,
        delay: 4,
        ease: "back.inOut",
        stagger: 0.2,
      }
    );
  }, []);

  return (
    <Container>
      <TitleContainer>
        <p className="line">{typingText}</p>
      </TitleContainer>
      <DescriptionContainer>
        {isNextVisible ? (
          <Player
            autoplay
            loop={false}
            keepLastFrame
            controls={false}
            src={debrisIntroTextDescription}
          />
        ) : null}
      </DescriptionContainer>
      <ButtonContainer
        ref={btnRef}
        onClick={() => {
          setIsMuted(false);
          setNextStage();
        }}
      >
        <span className="text-container">ENTER THE STORY</span>
      </ButtonContainer>
    </Container>
  );
};

export default IntroSreen;
