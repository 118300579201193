import { guiBind } from "../../global/GUI";
import { Root } from "../../Root";

export class Vignette {
  out = {
    uniforms: {
      vignettePower: Root.settings.devMode
        ? guiBind("post.vignette.power", 0.01, 2, 0.15)
        : { value: Root.settings.vignettePower },
    },
    declarations: /*glsl*/ `
uniform float vignettePower;
    `,
    inject: /*glsl*/ `{
      vec2 uv = vUv * (1. - vUv);
      float v = pow(16. * uv.x * uv.y, vignettePower);
      color *= v;
    }`,
  };
}
