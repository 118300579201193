import { CatmullRomCurve3, MathUtils, Vector3 } from "three";
import { Root } from "../../Root";

export class ProgressCurve extends CatmullRomCurve3 {
  constructor(points) {
    super(points.map((arr) =>
      new Vector3().fromArray(arr)
    ));
    
    const pIndex = Root.settings.camera.point1Index,
          p1 = new Vector3(...points[pIndex]);
    let p = Root.settings.camera.point1Index / (points.length - 1), // t
        d = this.getPointAt(p).distanceTo(p1);
    while (d > 1e-2) {
      p += d / this.getLength();
      // this may fail if p will jump over edge, but now it works
      // to fix mul by sign of dot(delta d, arc tangent at p)
      d = this.getPointAt(p).distanceTo(p1);
    }

    this.edge = p;
  }

  getP = p => {
    const t = p < .5 ?
      this.edge * MathUtils.smootherstep(p, 0, .5) :
      this.edge + (1 - this.edge) * MathUtils.smootherstep(p, .5, 1);

    return this.getPointAt(t);
  };
}