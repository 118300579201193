import { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import mouseScrollAnimation from "../assets/jsons/mouse-scroll-animation.json";
import styled from "styled-components";
import { device, deviceWidthInPixel } from "../const/device";
import useWindowSize from "../hooks/useWindowSize";

const Container = styled.div`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  @media ${device.mobileLMaxWidth} {
    bottom: 90px;
    #lottie {
      width: 16px !important;
    }
  }
`;

const MouseScrollAnimation = () => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width < deviceWidthInPixel.mobileL) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <Container>
      {isMobile ? null : (
        <Player
          autoplay
          loop={true}
          keepLastFrame={true}
          controls={false}
          src={mouseScrollAnimation}
        />
      )}
    </Container>
  );
};

export default MouseScrollAnimation;
