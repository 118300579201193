import { Player } from "@lottiefiles/react-lottie-player";
import styled from "styled-components";
import { useState, useRef } from "react";
import CustomLoading from "./CustomLoading";
import debrisIconComingInAnimation from "../../assets/jsons/debris-icon-coming-in-animation.json";
import useSceneStage from "../../hooks/useSceneStage";
import { useEffect } from "react";
import { device } from "../../const/device";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .lf-player-container {
    height: 50%;

    @media ${device.mobileLMaxWidth} {
      height: 35%;
    }
  }
`;

const DebrisIcon = ({ isStageComplete }) => {
  const { setNextStage } = useSceneStage();
  const animateRef = useRef(null);

  useEffect(() => {
    if (isStageComplete && animateRef.current) {
      animateRef.current.setPlayerDirection(-1);
      animateRef.current.play();
    }
  }, [isStageComplete, animateRef]);

  return (
    <Player
      autoplay
      loop={false}
      ref={animateRef}
      onEvent={(event) => {
        if (event === "complete" && isStageComplete) {
          setNextStage();
        }
      }}
      keepLastFrame
      controls={false}
      speed={1.2}
      src={debrisIconComingInAnimation}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
};

const LoadingScreen = ({ firstScreenLoadedResNum }) => {
  const [complete, setComplete] = useState(false);

  return (
    <Container>
      <DebrisIcon isStageComplete={complete} />
      <CustomLoading
        firstScreenLoadedResNum={firstScreenLoadedResNum}
        setComplete={() => setComplete(true)}
      />
    </Container>
  );
};

export default LoadingScreen;
