import React, { useState, useRef, useEffect, Fragment } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import newMouseScrollFrame from "../assets/jsons/new-mouse-scroll-frame.json";
import newHandScrollFrame from "../assets/jsons/new-hand-scroll-frame.json";
import mouseIconScroll from "../assets/jsons/mouse-icon-scroll.json";
import styled from "styled-components";
import { gsap } from "gsap";
import useSceneStage from "../hooks/useSceneStage";
import { deviceWidthInPixel } from "../const/device";
import useWindowSize from "../hooks/useWindowSize";

const Container = styled.div`
  min-width: 269px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FrameContainer = styled.div`
  margin: 0 auto;
`;

const MouseContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 71px;
`;

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 15%;
  font-family: "N27";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;

  .line {
    width: 200px;
  }
`;

const TEXT1 = "Keep your mouse scrolling for the best experience";
const TEXT2 = "Slowly scroll up for the best experience";
const INTERVAL_TIMING = 50;

const ScrollingConfirm = () => {
  const containerRef = useRef(null);
  const { setNextStage } = useSceneStage();
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isContainerShown, setIsContainerShown] = useState(false);
  const { width } = useWindowSize();
  const [typingText, setTypingText] = useState("");
  const index = useRef(-1);

  const handleWheel = () => {
    setLoaded(true);
  };

  useEffect(() => {
    index.current = -1;
    setTypingText("");
    if (isContainerShown) {
      const id = setInterval(() => {
        const text = isMobile ? TEXT2 : TEXT1;
        if (index.current < text.split("").length - 1) {
          setTypingText((prev) => {
            index.current = index.current + 1;
            return prev + text.split("")[index.current];
          });
        } else {
          clearInterval(id);
        }
      }, INTERVAL_TIMING);
      return () => {
        clearInterval(id);
      };
    }
  }, [isContainerShown, isMobile]);

  useEffect(() => {
    if (width < deviceWidthInPixel.mobileL) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (loaded) {
      const fadeOutTimeline = gsap.timeline();
      fadeOutTimeline.fromTo(
        containerRef.current,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          duration: 2,
          ease: "back.inOut",
          stagger: 0.2,
          onComplete: () => {
            setNextStage();
          },
        }
      );
    }
  }, [loaded, setNextStage]);

  useEffect(() => {
    const timeline = gsap.timeline();
    timeline.fromTo(
      containerRef.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 2,
        ease: "back.inOut",
        stagger: 0.2,
        onComplete: () => {
          setIsContainerShown(true);
        },
      }
    );

    window.addEventListener("wheel", handleWheel);
    window.addEventListener("touchmove", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleWheel);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      {isMobile ? (
        <Fragment>
          <FrameContainer>
            <Player
              autoplay
              loop={true}
              controls={false}
              src={newHandScrollFrame}
            />
          </FrameContainer>
          <TextContainer>
            <p className="line">{typingText}</p>
          </TextContainer>
        </Fragment>
      ) : (
        <Fragment>
          <FrameContainer>
            <Player
              autoplay
              loop={false}
              keepLastFrame={true}
              controls={false}
              src={newMouseScrollFrame}
            />
          </FrameContainer>
          <MouseContainer>
            <Player
              autoplay
              loop={true}
              controls={false}
              src={mouseIconScroll}
            />
          </MouseContainer>
          <TextContainer>
            <p className="line">{typingText}</p>
          </TextContainer>
        </Fragment>
      )}
    </Container>
  );
};

export default ScrollingConfirm;
