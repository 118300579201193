import { Player } from "@lottiefiles/react-lottie-player";
import signUpAnimation from "../assets/jsons/sign-up-animation.json";
import styled from "styled-components";
import { device } from "../const/device";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  #lottie {
    height: 160px;
    width: 480px;
    @media ${device.mobileLMaxWidth} {
      width: 300px !important;
    }
  }
`;

const SignUpAnimation = () => {
  return (
    <Container>
      <Player
        autoplay
        loop={false}
        keepLastFrame
        controls={false}
        src={signUpAnimation}
        style={{ height: "160px", width: "480px" }}
      />
    </Container>
  );
};

export default SignUpAnimation;
