import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import AsteroidInfoPanel from "./AsteroidInfoPanel";
import useSceneStage from "../../hooks/useSceneStage";
import { GL } from "./gl";

const settings = {
  camera: {
    positions: [
      [0, 0, 750],
      [-50, -10, 50],
      [-20, 8, -10],
      [0, 0, -10],
      [0, 0, -3],
      [3, -3, 0],
      [10, -10, 10],
    ],
    targets: [
      [50, 50, -50],
      [30, -10, 5],
      [5, 5, 15],
      [0, 0, 0],
      [0, 0, 0],
      [3, -3, 10],
      [10, -10, 20],
    ],
    point1Index: 3,
  },
  comet: {
    rotationSpeed: 0.5,
  },
  satellite: {
    rotationSpeed: 5.0,
  },
  light: {
    position: [10, 2, 4],
    intensity: 1,
    color: 0xffffff,
  },
  light2: {
    position: [-1, 0, -1],
    intensity: 0.175,
    color: 0xffffff,
  },
  starsLightIntensity: 0.2,
  DoF: {
    blurDistance: [0, 0, 1, 2, 3, 0, 0, 200, 1000],
    maxBlur: [0, 0, 0, 0, 0.2, 1, 1, 1, 0],
    focusShift: [0, 0, 0, -1, -2.5, -5, -21, -10, 0],
  },
  exposure: 1.5,
  haloPower: 0.4,
  bloomPower: 1,
  vignettePower: 0.25,
};

const Container = styled.div`
  /* position: fixed;
  width: 100%;
  height: 100%; */
`;

const AsteroidContainer = styled.div`
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100vw;
  height: 100vh;
  touch-action: pan-y; // fix double tap zoom
  -webkit-user-select: none;
  -webkit-touch-callout:none;
  user-select: none;
  > canvas {
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    touch-action: pan-y; // fix double tap zoom
    -webkit-user-select: none;
    -webkit-touch-callout:none;
    user-select: none;
  }
`;

const ScrollerWrapper = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;
const ScrollContent = styled.div`
  width: 100%;
  height: 55000px;
`;

function createWheelStopListener(element, action, callback, timeout) {
  let handle = null;
  let startPos = 0;
  let playForward = true;
  const onScroll = function (event) {
    playForward = event.deltaY > 0;
    action(playForward);
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout); // default 200 ms
  };
  const onTouchStart = (event) => {
    startPos = event?.touches[0]?.clientY;
  };
  const onTouchMove = (event) => {
    if (event?.changedTouches) {
      const touchend = event?.changedTouches[0]?.clientY;
      playForward = startPos - touchend > 0;
    }
    action(playForward);
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, 1200); // default 200 ms
  };
  // const onTouchEnd = () => {
  //   callback();
  // };
  element.addEventListener("wheel", onScroll);
  element.addEventListener("touchstart", onTouchStart);
  element.addEventListener("touchmove", onTouchMove);
  // element.addEventListener("touchend", onTouchEnd);
  return function () {
    element.removeEventListener("wheel", onScroll);
    element.addEventListener("touchstart", onTouchStart);
    element.addEventListener("touchmove", onTouchMove);
    // element.addEventListener("touchend", onTouchEnd);
  };
}

const AsteroidScreen = () => {
  const ref = useRef(null);
  const scrollRef = useRef(null);
  const glRef = useRef(null);
  const stageRef = useRef(0);
  const autoRef = useRef(null);
  const animateStageRef = useRef(0);
  const playForwardRef = useRef(true);
  const [complete, setComplete] = useState(false);
  const [show, setShow] = useState(false);
  const { stage, setNextStage } = useSceneStage();
  const [animateStage, setAnimateStage] = useState(0);

  useEffect(() => {
    if (ref.current && !glRef.current) {
      glRef.current = new GL(settings);
      glRef.current.load().then(() => {
        glRef.current.create({
          container: ref.current,
        });
        // hidePreloader()
      });
    }
  }, [ref, glRef]);

  useEffect(() => {
    if ([4].includes(stage) && ref.current && scrollRef.current) {
      animateStageRef.current = animateStage;
      if (
        animateStage === 0 &&
        stageRef.current === 0 &&
        playForwardRef.current
      ) {
        autoRef.current = gsap.timeline({
          repeat: 0,
          onReverseComplete: () => {
            playForwardRef.current = true;
          },
        });

        autoRef.current.to(scrollRef.current, {
          duration: 20,
          scrollTop: scrollRef.current.offsetHeight * (2 / 3),
          ease: "none",
          onUpdate: () => {
            const progress = autoRef.current.progress();
            glRef.current.setProgress(progress);
          },
          onComplete: () => {
            setShow(true);
            stageRef.current = 1;
          },
        });
      }
      createWheelStopListener(
        window,
        function (playForward) {
          if (stageRef.current === 1 && animateStageRef.current >= 3) {
            if (!playForward) {
              setShow(false);
              setAnimateStage(0);
              stageRef.current = 0;
              autoRef.current.reverse();
              playForwardRef.current = false;
            } else {
              stageRef.current = 2;
              autoRef.current.to(scrollRef.current, {
                duration: 10,
                scrollTop: 0,
                ease: "none",
                onUpdate: () => {
                  autoRef.current.totalProgress();
                  const progress = 1.6 * autoRef.current.progress();
                  if (progress >= 1.2 && stageRef.current === 2) {
                    stageRef.current = 3;
                    setShow(false);
                  }
                  glRef.current.setProgress(progress);
                },
                onComplete: () => {
                  stageRef.current = 4;
                  setComplete(true);
                },
              });
              autoRef.current.timeScale(4);
            }
          } else if (stageRef.current < 1) {
            if (!playForward) {
              autoRef.current.reverse();
              playForwardRef.current = false;
            } else {
              autoRef.current.timeScale(3);
            }
          }
        },
        function () {
          autoRef.current.timeScale(1);
        },
        200
      );
    }
  }, [glRef, stageRef, stage, scrollRef, autoRef, animateStage]);

  useEffect(() => {
    if (stage === 4) {
      setComplete(false);
      setShow(false);
      stageRef.current = 0;
    }
  }, [stage]);

  useEffect(() => {
    if (complete) {
      setNextStage();
    }
  }, [complete]);

  return (
    <Container id="continaer">
      <ScrollerWrapper ref={scrollRef}>
        <ScrollContent></ScrollContent>
      </ScrollerWrapper>
      <AsteroidContainer id="asteroid" ref={ref} />
      {show && (
        <AsteroidInfoPanel
          localStage={animateStage}
          setLocalStage={setAnimateStage}
        />
      )}
    </Container>
  );
};

export default AsteroidScreen;
